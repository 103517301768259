<template>

  <Carousel
      ref="slider"
      :breakpoints="breakpoints"
      v-model="currentSlide"
      @init="initSlider"
      @slide-start="initSlider"
  >
    <Slide v-for="(icon, index) in icons" :key="icon?.title">

      <div class="icon-item">
        <div class="icon-warp relative">
          <div class="icon">

            <SvgImage :key="icon?.image?.image?.node?.sourceUrl" :img="icon?.image?.image?.node?.svgHtml"
                      :is-html="true"/>
          </div>
          <div class="icon-hover">

            <SvgImage :key="icon?.image?.imageHover?.node?.sourceUrl" :img="icon?.image?.imageHover?.node?.svgHtml"
                      :is-html="true"/>
          </div>
        </div>
        <div class="subtitle-semibold font-weight-bold text-center">{{ icon?.title }}</div>
        <div class="subtitle text-center red-underline red-underline--short2">{{ icon?.subtitle }}</div>
      </div>
    </Slide>
  </Carousel>
</template>
<script setup>
const props = defineProps({
  icons: {
    type: Object,
    required: true,
  },
});
const slider = ref(null);
const currentSlide = ref(0);
const breakpoints = {
  100: {
    itemsToShow: 1,
    wrapAround: true,
    snapAlign: "center",
    autoplay: 2000,
    transition: 1000,
    dir: "rtl",
  },
  801: {
    itemsToShow: 3,
    dir: "rtl",
    itemsToScroll: 1,
    wrapAround: true,
    snapAlign: "center",
    autoplay: 2000,
    transition: 1000,
  },
};
const icons = computed(() => {
  return props.icons;
});
watchEffect(() => {
  // slider?.value?.updateSlideSize();
});

const initSlider = () => {

  // slider.value.restartCarousel();
  // slider.value.updateSlideSize();
};
onMounted(() => {
  //slider.value.updateSlideSize();
});

</script>
<style lang="scss">
.hp-about__icons {
  max-width: 100%;
}

.carousel {
  max-width: 100%;
}


.carousel__slide {
  &--active {
    .icon-item {
      .icon-warp {
        .icon {
          opacity: 0;
        }

        .icon-hover {
          opacity: 1;

          svg #rotate,
          svg > g:nth-of-type(1) {
            -webkit-animation: rotate 10s linear infinite;
            animation: rotate 10s linear infinite;
            -webkit-animation-direction: reverse;
            animation-direction: reverse;
          }
        }
      }
    }
  }

  .icon-item {
    width: 100%;
    @media screen and (max-width: 800px) {
      &:after {
        content: initial !important;
      }
      border-left: none !important;
    }
  }
}
</style>
